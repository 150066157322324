@import '../../commons/styles/global.scss';

.container {
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;

    @include desktop { 
        padding: 80px 40px;
    }
}

.video_card {
    width: 100%;
    background-color: #B2CCDF;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include tablet {
        border-radius: 30px;
    }
    @include desktop { 
        border-radius: 40px;
        max-width: 1000px;
    }
}

.video_content {
    width: 75%;
    margin: 50px 30px;

    @include tablet {
        width: 70%;
        margin: 80px 40px;
    }
    @include desktop { 
        width: 50%;
        margin: 100px 50px;

        & > p {
            font-size: 1.3rem;
        }
    }
}

.video_container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    & iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}