@import '../../commons/styles/global.scss';

.page_container {
    height: 100%;
    width: 100%;

    @include desktop { 
        display: flex;
        align-items: center;
    }
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    color: #000;
    // background-color: #03396C;
    
    @include desktop { 
        display: flex;
        // margin: auto;
        // Styles for desktop go here
    }
}

.name {
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1.6rem;
    margin: 0 0 20px;
}

.address {
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    margin: 15px 0;
}

.avatar {
    width: 300px !important;
    height: 300px !important;
}

.main_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    row-gap: 50px;

    @include desktop { 
        max-width: 1284px;
        padding: 0 20px;
        margin: auto;
        padding: auto;
        flex-direction: row;
        align-items: center;
        column-gap: 22px;
    }
}

.introduction {
    padding: 0 10px;
    letter-spacing: 0.1px;

    & p {
        margin-bottom: 15px;
    }

    @include desktop { 
        font-size: 1.2rem;
    }
}

.no_margin {
    margin: 0;
}

.links {
    padding: 0 10px;
    display: flex;
    align-items: center;
    column-gap: 40px;

    @include desktop { 
        max-width: 1284px;
        margin: auto;
        padding: auto;
        flex-direction: row;
        align-items: center;
        column-gap: 22px;
    }
}

.resume_button {
    border-radius: 20px !important;
    padding: 6px 30px !important;
    text-transform: capitalize !important;
    background-color: #B2CCDF !important;
    color: #000 !important;
    font-weight: 800 !important;
}

.social_links {
    display: flex;
    column-gap: 20px;
}

.social_icon {
    width: 50px;
    height: 50px;
}



