@import '../../commons/styles/global.scss';

.form_container {
    max-width: 700px;
    margin: auto;
    padding: 25px 10px;

    @include tablet {
        padding: 50px 0;
    }
    @include desktop { 
        padding: 80px 0;
    }
}

.icon_container {
    max-width: 700px;
    margin: auto;
    padding: 0 10px;
    color: #fff;
}

.contact_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin: auto;
}

.button {
    background-color: #B2CCDF !important;
    color: #000 !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    padding: 4px 40px !important;
    border-radius: 15px !important;

    &:hover {
        background-color: #88A4CF !important;
    }
}

.main_info {
    display: flex;
    column-gap: 20px;
}

.icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;

    @include tablet {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }
    @include desktop {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }
}

.icon_row {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.social_icon {
    width: 45px;
    height: 45px;

    @include tablet {
        width: 50px;
        height: 50px;
    }
    @include desktop {
        width: 60px;
        height: 60px;
    }
}
