.container {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.text_container {
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.name {
    font-size: 42px;
    font-weight: 800;
    text-transform: uppercase;
    margin: 80px 0 10px;
}

.position {
    font-size: 24px;
    font-weight: 400;
    text-transform: capitalize;
    margin: 0;
}


.video_container {
    width: 100vw;
    height: 60vh;
}

.video_frame {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
}

video {
    width: 100%;
    height: 100%;
    object-fit: fill;
}
