@import '../../commons/styles/global.scss';

.container {
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;

    @include desktop { 
        padding: 80px 40px;
    }
}

.photo_card {
    width: 100%;
    max-width: 1000px;

    background-color: #B2CCDF;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include desktop { 
        border-radius: 40px;
    }
}

.image_container {
    padding: 40px;

    @include tablet { 
        max-width: 60%;
     }
    @include desktop { 
       max-width: 50%;
    }

    & img {
        width: 100%;
        background-color: #B2CCDF;
    }
}